import Util from './Util.js'
import Lote from './Lote.js'

const Maps = {
    async loadManzana(manzana, desarrollo, setLotes) {
        let num = manzana.replace(/\D+/g, '')
        let position = Util.position(num)

        let ContainerManzana = document.getElementById(manzana)

        let block = desarrollo.blocks[position]
        let manzanaJson = block.find((m) => m.Numero == manzana)

        manzanaJson?.Lotes.forEach((l) => {
            // ContainerManzana.insertAdjacentHTML(
            //     'afterbegin',
            //     Util.parseOuterHTML(l)
            // )
        })
    },
    async getDisponiblidad(fraccionamiento, manzana) {
        // preloader.style.display = 'flex'
        // containerMapa.style.display = 'none'
        // let Tooltip = document.getElementById('info-lote')
        try {
              const data = await fetch(`/server/gc_system_web_qr_function/crm/getDisponibilidad/${fraccionamiento}/${manzana}`)
            // let tempDesartollo = await fetch(`/server/gc_system_web_function/getPlano/${nameSvg}`)
            let Products = await data.json()

            console.log("Products: ",Products)
            

            // const data = await crm.fetchDisponibilidad(fraccionamiento, manzana)

            if (Products.type == 'warning' || Products.type == 'success')
                this.pintarDisponibles()

            if (Products.ok) {
                console.log("products: ",Products)
                if (Products.data.length > 0)
                    this.poblarLotificacion(Products.data)
            }
        } catch (error) {
            console.log(error)
        }

        // this.hidePopup(Tooltip)
    },
    pintarDisponibles() {
        const tempLotes = document.querySelectorAll('.cls-2')
        const lostes2 = Array.from(tempLotes)

        lostes2.forEach((lote) => {
            try {
                if (lote.id.includes('L')) {
                    lote.style.fill = '#f8c15b'
                    lote.style.stroke = '#000'
                    lote.setAttribute('stroke-width', '1')
                    lote.dataset.lote = ''
                    lote.dataset.crm = false
                    lote.classList = 'login'
                    lote.dataset.disponible = true
                }
            } catch (err) {
                console.log(err)
            }
        })
        // preloader.style.display = 'none'
        // containerMapa.style.display = 'flex'
    },
    poblarLotificacion(disponibilidad) {
        disponibilidad.forEach((product) => {
            try {
                let lote = ''
                if (product.Lote_Letra == null) {
                    lote = document.getElementById(
                        `M${product.Manzana}-L${product.Lote}`
                    )
                } else {
                    lote = document.getElementById(
                        `M${product.Manzana}-L${
                            product.Lote + product.Lote_Letra
                        }`
                    )
                }

                if (lote !== null) {
                    lote.dataset.crm_id = product.id
                    lote.dataset.sku = product.Manzana_y_Lote
                    lote.dataset.trato = product.Product_Name
                    lote.dataset.crm = true
                    lote.dataset.costototal = product.Unit_Price
                    lote.dataset.dimension = product.Dimension_del_Terreno_M21
                    lote.dataset.costom2 = product.Costo_por_M2
                    lote.dataset.fracc_name = product.Fraccionamiento.name
                    lote.dataset.fracc_id = product.Fraccionamiento.id
                    lote.dataset.estado = product.Estado
                    lote.dataset.esquina = product.Es_esquina

                    lote.style.fill = this.statusColor(product.Estado)
                    lote.style.stroke = '#000'

                    if (product.Estado != 'Disponible') {
                        lote.removeAttribute('onclick')
                        lote.dataset.disponible = false
                    } else if (product.Estado == 'Disponible') {
                        lote.dataset.disponible = true
                    }
                }
            } catch (err) {
                console.log(err)
            }
        })
    },

    showPopup(e, x, y) {
        const tooltip = document.getElementById('info-lote')
        // if (e.target.dataset.crm == 'true') {
            // if (e.target.dataset.disponible == 'true') {
                tooltip.innerHTML = `
                    <p>${e.target.dataset.trato}</p>
                    <p>Dimension: ${e.target.dataset.dimensionM2} m2</p>
                    <p>Costo M2: $ ${e.target.dataset.costoM2}</p>
                    <p>Costo Total: $ ${e.target.dataset.costoTotal}</p>
                `
                e.target.style.cursor = 'pointer'
            // } 
    //   }
        tooltip.style.left = x + 'px'
        tooltip.style.top = y + 'px'
        tooltip.style.display = 'block'
    },
    hidePopup() {
        const tooltip = document.getElementById('info-lote')
        tooltip.style.display = 'none'
    },
    statusColor(status) {
        const colors = [
            { status: 'Disponible', fill: '#de9f27' },
            { status: 'Apartado', fill: '#b5512a' },
            { status: 'Primer Mensualidad', fill: '#7de38e' },
            { status: 'Enganche', fill: '#398afa' },
            { status: 'Contado', fill: '#6908c9' },
            { status: 'Vendido', fill: '#5C5C5C' },
            { status: 'Bloqueado', fill: '#ff2e2e' },
            { status: 'Reubicacion', fill: '#59f4ff' },
            { status: 'P. Reubicacion', fill: '#7d7d82' },
            { status: 'No Existe', fill: '#dbdbdb' },
        ]

        const findClass = colors.find((clr) => clr.status === status)
        return findClass.fill
    },
    inputSelect (e) {
        let engancheOpcion = document.getElementById('enganche-option')
        let inputPM = document.getElementById('input-mensualidad')
        if(e.target.checked){
            engancheOpcion.disabled = false
        }
        else{
            engancheOpcion.disabled = true
        }
    }
}

export default Maps