
import Util from "./Util.js"
import parse from 'html-react-parser'


export default function Detalles({ element }){

    
    // let path = element.path 
    let xmlString = Util.parseOuterHTML( element )

    return( <>{ parse(xmlString)}</> )
}