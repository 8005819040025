
import '../css/loading.css'

export default function Loading(){


    return( 
        <div className="loading">
            <div className="pulse-effect">
            <div className="sh1"></div>
            <div className="sh2"></div>
            <h4 className="lt">Cargando</h4>
            </div> 
        </div>    
    )
}