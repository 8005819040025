import Maps from "./Maps.js";
import Util from "./Util.js";
import axios from 'axios'

const UI = {
    async loadPlano(e) {
        // const mapa = document.getElementById("map");
        console.log('eLOAD', e)
        // const id = e.target.dataset.id;
        const name = e;
        // const localidad = e.target.dataset.localidad;

        // const nameSvg = name.replaceAll(" ", "-");

        let tempDesartollo = await fetch(
            `/server/gc_system_web_qr_function/desarrollos/getPlano/${name}`
        );
        let desarrollo = await tempDesartollo.json();
        console.log(desarrollo)
        return desarrollo.data
    },
    async getDisponibilidad(f,mArray, setLotes){
        let products = []
        let hasMore = false
        let pageCount = 1
        do {
            
            let config = {
                url: `/server/gc_system_web_qr_function/crm/disponibilidad?f=${f}&page=${pageCount}`,
                type: 'GET'
            }
            const request = await axios(config)
            if(request.data.ok){
                console.log("getDisponibilidad request: ",request)
                // console.log(request.data)
                products = [...products, ...request?.data?.data?.data]
                // products = request.push(...request.data.data.data)

                hasMore = request?.data?.data?.info?.more_records 
                console.log(hasMore)
                pageCount ++
            }else{
                hasMore = false
            }
        } while (hasMore);
        console.log(pageCount)
        console.log("products: ", products)

        
        const Lotes = mArray?.map((l) => {
            // console.log("test")
            let objReturn = {}

            let auxid1 = l.split('id="')
            let auxid2 = auxid1[1].split('" class')

            let id = auxid2[0]

            // console.log("request: ", request?.data?.data)
             // console.log("find ",id ," - ", `M${p?.Manzana}-L${p?.Lote}${p?.Lote_Letra != null ? p?.Lote_Letra : ''}`, " - ",p.Estado)
            let disponible = products.find((p) => id == `M${p?.Manzana}-L${p?.Lote}${p?.Lote_Letra != null ? p?.Lote_Letra : ''}`)
            // console.log(id)
            // console.log("disponible: ",disponible)
            // console.log("-----------------------------------------------------------")
            objReturn.path = l
            objReturn.request = disponible

            return objReturn
        })

        // console.log("Lotes: ",Lotes)
        // setLotes(Lotes)
        // const Lotes = [{path: 'test',request: undefined}]
        setLotes(Lotes)

       return Lotes
    //    return request.data.data
        
    },
    async getLotes(blocks){
        const lotes = new Array()
        const Numeros = new Array()
        
        blocks.forEach((block) => {
            if (block !== null && block !== []) {
                block.forEach(async (manzana) => {


                    if (manzana?.path)
                    Numeros.push(Util.parseOuterHTML(manzana?.path))

                    manzana?.Lotes.forEach((l) => lotes.push(Util.parseOuterHTML(l)))

                    

                });
            }
        });
        return {lotes: lotes, Numeros: Numeros}
    },
    async getSVG(name, desarrollo, setLotes) {
        const containerManzanas = document.getElementById("maps");

        // containerManzanas.innerHTML = "";
        desarrollo.blocks.forEach((block) => {
            if (block !== null && block !== []) {
                block.forEach(async (manzana) => {
                    if (manzana?.path)
                        // containerManzanas.insertAdjacentHTML(
                        //     "beforeend",
                        //     `<g class="cls-1" id="${manzana.Numero}" data-conatiner="${manzana.Numero}" ></g>`
                        //     // ${util.parseOuterHTML(manzana?.path)}
                        // );
                    Maps.loadManzana(manzana.Numero, desarrollo, setLotes);

                    console.log("getSVG - name: ",name)

                    Maps.getDisponiblidad(name, manzana.Numero);
                });
            }
        });

        let mapsDetails = document.getElementById("details");

        mapsDetails.innerHTML = "";

        desarrollo.details.forEach((detail) => {
            detail.forEach((d) => {
                mapsDetails.insertAdjacentHTML("beforeend", Util.parseOuterHTML(d));
            });
        });
    },
    showTooltip(type,lineaX,lineaY) {         
        let mapa = document.querySelector('.maps')        
         let posicionY = 0         
         let posicionX = 0          

         mapa.addEventListener(`${type}`, (e) => {             
            if (e.target.matches('[data-lote]')) {                 
                if(type == 'click')
                {
                    e.target.classList.add('lote-selected')
                } 
                e.target.classList.remove('lote-selected')
                posicionX = e.pageX -lineaX                 
                posicionY = e.pageY -lineaY               
                Maps.showPopup(e, posicionX, posicionY)            
            }         
        })         
              
              
        },
        hideTooltip() {         
            let mapa = document.querySelector('.maps')         
            mapa.addEventListener('mouseout', (e) => {             
            if (e.target.matches('[data-lote]')) {                 
                Maps.hidePopup()             
            }         
        })     
    },
    navegador() {
        let details = navigator.userAgent
        let regexp = /android|iphone|kindle|ipad/i
        let isMobileDevice = regexp.test(details)
        let objetReturn = {}

        if (isMobileDevice) {
            objetReturn.device = 'Mobile'
        } else {
            objetReturn.device = 'Web'
        }

        let es_chrome = details.toLowerCase().indexOf('chrome') > -1
        let es_firefox = details.toLowerCase().indexOf('firefox') > -1
        let es_opera = details.toLowerCase().indexOf('opr') > -1
        let es_safari = details.toLowerCase().indexOf('safari') > -1

        if (es_chrome) {
            objetReturn.browser = 'chrome'
        }
        if (es_firefox) {
            objetReturn.browser = 'firefox'
        }
        if (es_opera) {
            objetReturn.browser = 'opera'
        }
        if (es_safari) {
            objetReturn.browser = 'safari'
        }
        return objetReturn
    }

};



export default UI;
