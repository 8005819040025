import Util from './Util.js'
import parse from 'html-react-parser'

export default function Lote({ element, handleClickModal }) {
 
    // const handleClickLote = (e) => {
    //     handleClickModal(e)
    // }
    let path = element.path 
    let xmlString = Util.parseOuterHTML( path )
    let classN = ''
    // console.log(element?.request)

    if(element?.request?.Estado === 'Disponible'){
        classN = "disponible"
    }else{
        classN = "no-disponible"
    }
    // console.log(xmlString)
    const loteSplit = xmlString.split('></')
    const Lote = parse(`${loteSplit[0]} data-id="${element?.request?.id}" data-trato="${element?.request?.Product_Name}" data-sku="${element?.request?.Manzana_y_Lote}" data-dimension-m2="${element?.request?.Dimension_del_Terreno_M21}" data-costo-total="${element?.request?.Unit_Price}" data-costo-m2="${element?.request?.Costo_por_M2}" ${loteSplit[1]}`)
    if(element?.request?.Estado === 'Disponible'){ 
        // console.log(Lote)
    }
    // Lote?.props?.set("data-id", element?.request?.id) 

    return( <g className={classN} data-id={element?.request?.id} onClick={handleClickModal}>{ Lote }</g> )
    // return (<h1>{element?.request?.Estado}</h1>)
}
