
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import Lote from "./Lote.js";
import Detalles from './Details.js'
import Loading from "./loading.js";
import UI from "./UI.js";
import Panzoom from '@panzoom/panzoom'
import { useParams } from 'react-router-dom'


export default function Mapas() {
    const [fracc, setFracc] = useState();
    const [nameSvg, setNameSvg] = useState();
    const [nameFacc, setNameFacc] = useState();
    const [logo, setLogo] = useState();
    let navegador = UI.navegador()

    let nombreFraccionamiento = useParams()
    console.log("nombreFraccionamiento: ", nombreFraccionamiento)
    let nameFracc;
    
    const handleClickMenu = async () => {
        let name = nombreFraccionamiento.fracc;
        console.log('nombreFracc:', name)
        nameFracc = name.replaceAll("-", " ");
        console.log('nameFracc', nameFracc)
        

        setNameSvg(name)
        setNameFacc(nameFracc)

        document.title = `Desarrollos | ${nameFracc[0].toUpperCase()+nameFracc.substring(1)}`;
        const desarrollo  = await UI.loadPlano(name)
        console.log('desarrollo', desarrollo)
        setFracc(desarrollo)

    };

    const loadFracionamientos = async () => {
        let fraccionamientos = await fetch(
          "/server/gc_system_web_qr_function/desarrollos/getFraccionamiento"
        );
        let aux = await fraccionamientos.json();
        console.log('aux', aux)
        aux?.data?.data?.map((index) => {
            if(index.Name.toLowerCase() === nameFracc){
                setLogo(index.logo)
            }
        })
    };
    
    useEffect(() => {
        handleClickMenu()
        loadFracionamientos()
    },[]);

    const svg = useRef(null);
    const zoomin = useRef(null);
    const zoomout = useRef(null);
    const zoomreset = useRef(null);
    // const { fracc, handleClickMenu, nameSvg, nameFacc } = props

    const [lotes, setLotes] = useState()
    const [details, setDetails] = useState()
    
    const [loading, setLoading] = useState(true)

    const getDisponibilidad = async (nameFracc,fracc) => {
        // setLoading(true);
        setDetails( fracc?.Detalles )
        const disponibilidad = await UI.getDisponibilidad(nameFracc,fracc?.Lotes, setLotes)
        console.log('disponibilidad', disponibilidad)
        setLoading(false)
        
        // console.log("Mapa Lotes: ",lotes)
    }

    useEffect(() => {
        if(nameFacc){
            getDisponibilidad(nameFacc,fracc)
            const elem  = svg.current;
            const panzoom = Panzoom(elem)
            const ZoomIn = zoomin.current;
            const ZoomOut = zoomout.current;
            const ZoomReset = zoomreset.current;
            ZoomIn.onclick = function(){
                panzoom.zoomIn();
                console.log('ZoomIn');
            }
            ZoomOut.onclick = function(){
                panzoom.zoomOut();
            }
            ZoomReset.onclick = function(){
                panzoom.reset();
            }
            if((
                navegador.browser === 'chrome' ||
                navegador.browser === 'firefox' ||
                navegador.browser === 'safari') &&
                navegador.device === 'Mobile'
            ){
                UI.showTooltip('click',120,0)
            } else {
                UI.showTooltip('mouseover',160,-5)
            }
            UI.hideTooltip()
        }
    }, [fracc]);

    return (
        <div className="maps">
            {loading && <Loading/>}
            <p className="name-map">{nameFacc}</p>
            <div className="logo-map"><img src={logo}></img></div>
            <svg ref={svg}
                data-desarrollo=""
                
                viewBox={fracc?.viewBoxSVG[0]}
                id="svg-map"
            >
                <defs>
                    <style id="style">{`@import url(./css/desarrollos/${nameSvg}.css);`}</style>
                    <filter id="filter" filterUnits="userSpaceOnUse">
                    
                        <feComposite result="composite" operator="in" in2="SourceGraphic" />
                        <feBlend result="blend" in2="SourceGraphic" />
                    </filter>
                </defs>
                <g className="cls-0" id="maps" pointerEvents="all">
                {lotes == undefined ? (<></>) :(

                    // console.log(lotes)
                    lotes.map((l,i) => {
                        return(<Lote key={i} element={l}/>) 
                    })
                )}
                
                </g>
                <g className="cls-0" id="details">
                    {details == undefined ? (<></>) :(
                        details.map((d,i) => {
                            return (<Detalles key={i} element={d} />) 
                        })
                    )}
                </g>
            </svg>
            
            <div id="info-lote"></div>
            <div className="zoom-panel">
                <div className="zoom in" ref={zoomin}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25px" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                    </svg>
                </div>
                <div className="zoom out" ref={zoomout}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25px" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM13 10H7" />
                    </svg>
                </div>
                <div className="zoom reset" ref={zoomreset}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25px" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                </div>
            </div>
            
            <div className="container-colors color-lote">
            <div className="indicador">
                <span className="color" style={{backgroundColor: "#ffc423cc"}}></span>
                <div className="estado">Disponible</div>
            </div>
            <div className="indicador">
                <span className="color" style={{backgroundColor: "#dbdbdbfe"}}></span>
                <div className="estado">No Disponible</div>
            </div>
            </div>
        </div>
    );
}

