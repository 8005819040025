import {HashRouter as Router,Routes,Route} from "react-router-dom";
import "./App.css";
import Mapas from "./components/Mapas";
import './css/mapas.css'
import Footer from "./components/Footer.js"
function App() {
  
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/:fracc" element={<Mapas/>}>
          </Route>       
        </Routes>
      </Router>
      {/* {<Menu handleClickMenu={handleClickMenu}/>} */}
      <Footer/>
    </div>
  );
}

export default App;
